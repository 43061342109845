import Link from "next/link";
import Image from "next/image";
import { Typography } from "@libs/src/components/Typography";
import { routes } from "@webelight-site/utils/routes";
import {
  angleDownHeight,
  angleDownWidth,
} from "@webelight-site/constants/image-dimensions";
import angleLeft from "@webelight-site/assets/angle-left.svg";

export const CustomErrorPage = () => (
  <main className="main font-nunito wb-min-h-[calc(100vh-393px)]">
    <section className="wb-py-10">
      <div className="main-container wb-py-3 flex-center wb-flex-col wb-gap-8">
        <Typography
          tag="h1"
          className="wb-font-extrabold wb-text-9xl wb-text-dark wb-text-center"
        >
          500!
        </Typography>
        <Typography
          tag="span"
          className="wb-text-xl wb-tracking-wide wb-font-semibold"
        >
          UNEXPECTED ERROR
        </Typography>
        <Typography tag="p" className="wb-text-base wb-text-center">
          Oops! Something went wrong. <br />
          We apologize for any inconvenience caused. Please try again later.
        </Typography>
        <div className="wb-flex">
          <Link
            href={routes.HOME}
            className="wb-px-4 wb-py-3 wb-mt-1 wb-mb-4 wb-rounded wb-font-semibold flex-center wb-gap-2 wb-bg-primary wb-text-light"
          >
            <Image
              src={angleLeft}
              alt="angle-left"
              width={angleDownWidth}
              height={angleDownHeight}
              className="wb-opacity-35"
            />
            GO BACK TO HOME PAGE
          </Link>
        </div>
      </div>
    </section>
  </main>
);
